








































import {
  defineComponent,
  useContext,
  useRoute,
  ref,
  computed,
  useFetch,
  useRouter
} from '@nuxtjs/composition-api';
import { usePartFinderStore } from '~/modules/part-finder/stores/partFinder';
import { storeToRefs } from 'pinia';
import FilterableProductsLayout from 'components/catalog/FilterableProductsLayout.vue';
import Dropdowns from '../components/Dropdowns.vue';
import { SfHeading, SfButton } from '@storefront-ui/vue';
import type { Aggregation } from '@wemade-vsf/magento-api';
import type { MetaInfo } from 'vue-meta';

export default defineComponent({
  name: 'Parts',
  components: {
    FilterableProductsLayout,
    Dropdowns,
    SfHeading,
    SfButton,
    CategoryFilters: () => import('components/catalog/Category/Filters/CategoryFilters.vue')
  },
  setup () {
    const { i18n, localeRoute } = useContext();
    const store = usePartFinderStore();

    const {
      brands,
      models,
      finders,
      selectedBrand,
      selectedModel,
      selectedFinder
    } = storeToRefs(store);

    const {
      loadModels,
      loadFinders,
      setBrand,
      setModel,
      setFinder
    } = store;

    const route = useRoute();
    const { finder, brand, model } = route.value.params;

    const breadcrumbs = ref([
      { text: i18n.t('Home'), link: localeRoute({ name: 'home' }).fullPath },
      { text: i18n.t('part-finder.spare-parts'), link: localeRoute({ name: 'part-finder' }).fullPath }      
    ]);

    const title = computed(() => {
      if (selectedBrand.value && selectedModel.value) return `${selectedBrand.value?.name} ${selectedModel.value?.name}`;
      return i18n.t('Search results');
    })

    const filter = ref<Record<string, string> | null>({
      pf_finder: finder,
      pf_brand: brand,
      pf_model: model
    });

    useFetch(async () => {
      if (!finders.value.length) {
        await loadFinders()
      }
      if (!selectedFinder.value || selectedFinder.value.slug !== finder) {
        const finderToSet = finders.value.find(f => f.slug === finder);
        if (finderToSet) {
          await setFinder(finderToSet);
        }
      }
      if (!selectedBrand.value || selectedBrand.value.slug !== brand) {
        const brandToSet = brands.value.find(b => b.slug === brand);
        if (brandToSet) {
          await setBrand(brandToSet);
        }
      }

      if (!models.value.length) {
        await loadModels();
      }
      if (!selectedModel.value || selectedModel.value.slug !== model) {
        const modelToSet = models.value.find(m => m.slug === model);
        if (modelToSet) {
          await setModel(modelToSet);
        }
      }
      if (selectedFinder.value && selectedBrand.value && selectedModel.value) {
        breadcrumbs.value.push({ text: selectedFinder.value.name, link: localeRoute({ name: 'part-finder-finders', params: { finder: selectedFinder.value.slug } }).fullPath });
        breadcrumbs.value.push({ text: selectedBrand.value.name, link: localeRoute({ name: 'part-finder-models', params: { finder: selectedFinder.value.slug, brand: selectedBrand.value.slug } }).fullPath });
        breadcrumbs.value.push({ text: selectedModel.value.name, link: localeRoute({ name: 'part-finder-parts', params: { finder: selectedFinder.value.slug, brand: selectedBrand.value.slug, model: selectedModel.value.slug } }).fullPath });
      }
    })

    const isValid = computed(() => selectedBrand.value && selectedModel.value)
    const router = useRouter();
    function newSearch() {
      if (!isValid.value) return;
      router.push(localeRoute({
        name: 'part-finder-parts',
        params: {
          finder: selectedFinder.value.slug,
          brand: selectedBrand.value.slug,
          model: selectedModel.value.slug
        }
      }))
    }

    function walkAggregations(aggregations: Aggregation[]): Aggregation[] {
      return aggregations;
    }

    return {
      breadcrumbs,
      filter,
      isValid,
      title,
      newSearch,
      walkAggregations
    }
  },
  head(): MetaInfo {
    return {
      title: String(this.title)
    }
  }
})
